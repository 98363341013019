
  import { Component } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import { plainToInstance } from 'class-transformer'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Deal, Lead } from '@/entities/crm'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import StockPrices from '@/components/toolkit/details/row/custom/StockPrices.vue'
  import AutoHeader from '@/components/toolkit/details/row/expandable/auto/header.vue'
  import Simple from '@/components/toolkit/details/row/simple.vue'
  import { Consignment, Negotiation } from '@/entities/purchase'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Process } from '@/entities/settings'
  import { fixPrice, isValidNumber, stringifySafe } from '@/utils/general'
  import { PurchaseView } from '@/components/forms/view/PurchaseView'
  import { Payment, PaymentOrder } from '@/entities/finance'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GLoading from '@/components/core/GLoading.vue'
  import logOut from '@/components/LogOut.vue'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import { Attribute } from '@/entities/vehicle'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { mapActions } from 'vuex'

@Component({
  components: {
    GCostField,
    GRadioButton,
    AutoResume,
    logOut,
    GLoading,
    GAlert,
    GFiles,
    Simple,
    StockPrices,
    FieldTitle,
    LinkedPerson,
    InitialFields,
    LinkedAuto,
    BaseForm,
    AutoHeader,
  },
  methods: {
    ...mapActions('resources/form', ['withdrawalByPurchaseId']),
  },
})
  export default class ConsignmentForm extends PurchaseView {
  withdrawalByPurchaseId!: ({ purchaseId, idValidator }) => Promise<any>

  negotiation = plainToInstance(Negotiation, {})
  lead = plainToInstance(Lead, {})
  documents = []
  consignment = plainToInstance(Consignment, {})
  process: Process = plainToInstance(Process, {})
  deal: Deal = plainToInstance(Deal, {})
  attributes: Attribute[] = []
  payments: Array<Payment> = []
  paymentOrder: PaymentOrder = plainToInstance(PaymentOrder, {})
  panels = [0]
  purchase = {
    open: false,
    title: '',
    item: null,
  }

  consignmentMetadata = null
  title = ''
  idProcess = null
  showDetail = false
  fileInfo = null
  existDocument = false

  declare $refs: {
    form: HTMLFormElement
  };

  metadata = {}
  radioButtonMessage = {
    hasCost: '',
    isContractRetirement: '',
    isContractAnnulled: '',
  }

  formData = {
    mileage: null,
    hasCost: null,
    cost: null,
    contractRetirement: [],
    contractAnnulled: [],
    owners: [],
    signers: [],
    payments: [],
    isContractRetirement: null,
    commentContractRetirement: '',
    isContractAnnulled: null,
    commentContractAnnulled: '',
  }

  section = {
    contract: false,
    payment: false,
  }

  fields = {
    contractRetirement: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
    contractAnnulled: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
  }

  async mounted () {
    this.metadata = {}
    this.loadingForm = true
    this.loadingCar = true
    await this.setMetadata()

    const { id, uid, title, consignmentMetadata } = this
    const purchaseId = id || uid

    if (isValidNumber(purchaseId)) {
      await this.getPurchaseInfo(purchaseId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(consignmentMetadata, title, Boolean(this.consignment?.id))
    }

    await this.setTheBackup()
    await this.setPayments()
    await this.setDetails()

    this.loadingForm = false
  }

  async setTheBackup () {
    const { backup } = this
    if (!backup) return

    if (backup && 'consignmentForm' in backup && backup.consignmentForm) {
      this.formData = backup.consignmentForm
    }
  }

  async setPayments () {
    const { consignment } = this

    if (!consignment?.id) return
    this.formData.payments = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.consignment.id } },
          { id_process: { _eq: this.idProcess } },
          { id_payment_recipient: { _is_null: true } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })
  }

  cancelPurchase () {
    const { formData } = this
    this.setBackup(null)
    const buyFile = formData?.buyFile?.length ? [...formData?.buyFile] : []
    const notarizedAuthorization = formData?.notarizedAuthorization?.length ? [...formData?.notarizedAuthorization] : []
    const legalReport = formData?.legalReport?.length ? [...formData?.legalReport] : []
    this.close([...buyFile, ...notarizedAuthorization, ...legalReport])
  }

  async getPurchaseInfo (id) {
    this.consignment = await this.fetchData({
      query: { name: 'fetchLite', model: 'Consignment', params: { id } },
      force: true,
    })

    this.documents = await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: { _and: [{ id_process_record: { _eq: this.consignment.id } }, { type: { process: { table_name: { _eq: 'purchase_order' } } } }] },
      force: true,
    })

    const document = this.documents.find(document => document.documentType.name === 'consignment_contract')
    const { interveners = [] } = document
    this.formData.signers = interveners?.filter(intervener => intervener.field.name === 'sale_representative').map(intervener => intervener.person)

    this.deal = await this.fetchData({
      query: { name: 'fetchLite', model: 'Deal', params: { id: this.consignment?.negotiation?.inspection?.deal?.id } },
      force: true,
    })

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'purchase_order' } } },
    })
    this.fileInfo = fileInfo

    await this.setMileage()
    this.setProperties(fileInfo, 'consignment_withdrawal', 'contractRetirement')
    this.setProperties(fileInfo, 'purchase_sale_cancellation', 'contractAnnulled')
    await this.getFiles()

    this.formData.owners = this.deal?.ownership?.owners?.map(owner => owner.person)
    this.consignment.owners = this.formData.owners
    this.consignment.executive = this.deal.lead.executive
  }

  async getFiles () {
    const { consignment, idProcess } = this

    this.formData.contractRetirement = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: consignment.id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { name: { _eq: 'consignment_withdrawal' } } },
        ],
      },
      force: true,
    })

    this.formData.contractAnnulled = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: consignment.id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { name: { _eq: 'purchase_sale_cancellation' } } },
        ],
      },
      force: true,
    })
  }

  async setMileage () {
    const km = (await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: { slug: { _eq: 'mileage' } },
    }))[0]

    const existDocument = this.documents.find(document => document.documentType.name === 'consignment_withdrawal_contract')

    const exist = (await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.consignment.id } },
          { id_process: { _eq: this.idProcess } },
          { id_deal: { _eq: this.deal.id } },
          { id_component: { _eq: km.id } },
        ],
      },
      force: true,
    }))[0]

    this.formData.mileage = exist?.value
    this.formData.hasCost = existDocument?.metadata?.hasCost
    this.formData.cost = existDocument?.metadata?.cost
    this.existDocument = Boolean(existDocument?.id)
  }

  async setMetadata () {
    const { metadata } = this.getForm('Consignment', 'validator_consignment')
    const { form } = metadata as Form
    this.consignmentMetadata = metadata
    this.title = form.title

    await this.setFilesFieldsData()
  }

  async setFilesFieldsData () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'purchase_order' } },
    })

    const { id } = process[0] as Record<string, any>

    this.idProcess = id
  }

  async setDetails () {
    const { consignmentMetadata, consignment, negotiation } = this
    const lead = negotiation?.inspection?.deal?.lead

    if (lead?.id) {
      const deals = await this.fetchData({
        query: { name: 'findLite', model: 'Deal' },
        filter: { id_lead: { _eq: lead.id } },
        force: true,
      })
      consignment.dealSale = deals?.find(deal => deal.isSale)
    }

    this.metadata = {
      data: consignment,
      metadata: consignmentMetadata,
    }

    this.showDetail = Boolean(consignment?.id)
  }

  async preSend () {
    this.panels = [0, 1]
    await this.send()
  }

  validateFields () {
    const { formData } = this
    const keys = ['isContractRetirement', 'isContractAnnulled']

    let flag = false
    keys.forEach(key => {
      if (formData[key] === null) {
        this.radioButtonMessage[key] = 'Campo requerido'
        flag = true
      }
    })

    return flag
  }

  async send () {
    const { isNotToConfirm } = this

    if (isNotToConfirm) {
      await this.close()
      return
    }

    if (!this.$refs.form.validate() || this.validateFields()) {
      await this.setFieldError()
      return
    }

    this.loadingForm = true

    const { consignment, formData } = this

    const existDocument = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        _and: [
          { id_document_type: { _eq: document[0]?.id } },
          { id_process_record: { _eq: consignment.id } },
        ],
      },
      force: true,
    }))[0]

    if (formData.contractAnnulled.length) {
      await this.insertFileProcessInfo({
        id: formData.contractAnnulled[0].id,
        comment: formData.commentContractAnnulled,
        valid: formData.isContractAnnulled,
        validation_type: 'manual',
      })
    }

    if (formData.contractRetirement.length) {
      await this.insertFileProcessInfo({
        id: formData.contractRetirement[0].id,
        comment: formData.commentContractRetirement,
        valid: formData.isContractRetirement,
        validation_type: 'manual',
      })
    }

    const someToUpdate = formData.isContractAnnulled === false || formData.isContractRetirement === false

    if (someToUpdate) {
      await this.pushData({
        model: 'Consignment',
        fields: {
          id: consignment.id,
          id_process_status: this.purchaseOrderStatus.pickupToUpdate[0].id,
        },
      })

      await this.pushData({
        model: 'Document',
        fields: {
          id: existDocument.id,
          id_process_status: this.statusDocumentToUpdate[0].id,
        },
      })
    } else {
      await this.withdrawalByPurchaseId({ purchaseId: consignment.id, idValidator: this.idEmployee })
    }

    await this.close()
  }

  get change () {
    const { formData, panels, section, consignment } = this

    return stringifySafe([formData, panels, section, consignment])
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  isValidCost () {
    const { formData: { hasCost } } = this

    if (hasCost === null) {
      this.radioButtonMessage.hasCost = 'Campo requerido'
      return true
    }
    this.radioButtonMessage.hasCost = ''
    return false
  }

  getIcon (item) {
    if (item?.closingReason?.name === 'successful') {
      return 'mdi-eye'
    }
    return 'mdi-pencil-outline'
  }

  get disabledApproved () {
    const { consignment } = this
    return consignment?.status?.isApproved || consignment?.status?.isToConfirm
  }

  get purchaseIsApproved () {
    return false
  }

  get paymentError () {
    const { formData: { payments, cost } } = this
    if (!payments) return ''
    return this.paymentValid(payments, cost) ? '' : `La suma de los pagos debe ser igual al costo de retiro ${fixPrice(cost)}`
  }

  get isLoading () {
    const { loadingForm, loadingCar } = this

    return loadingForm || loadingCar
  }

  get displayMessage () {
    const { formData } = this

    return formData.payments.some(payment => payment?.status?.isPending)
  }

  get disableFieldsAfterPay () {
    const { formData, paymentError } = this

    return formData?.payments?.every(payment => !payment?.status?.isPending) && Boolean(!paymentError?.length)
  }

  paymentValid (payments, cost) {
    const suma: number = payments.reduce((total, item) => {
      return total + Number(item.amount)
    }, 0)

    return suma === Number(cost)
  }

  get isNotToConfirm () {
    const { consignment } = this

    return consignment?.status?.name !== 'pickup_to_confirm'
  }

  setLoadingCar () {
    this.loadingCar = false
  }
  }
